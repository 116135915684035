import React from "react"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class LabQuoteForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      name: "",
      company: "",
      contactNumber: "",
      message: "",
      submitButtonDisabled: false,
      asyncRequests: [],
      form: "",
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    let { email, name, company, contactNumber, message } = this.state
    this.setState({ form: e.target })

    this.setState({ submitButtonDisabled: true })
    fetch("https://wh.automate.io/webhook/5f5ef7afc60ea62dfeaca07b", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        email: email,
        name: name,
        company: company,
        contactNumber: contactNumber,
        message: message,
      }),
    }).then(() => {
      alert(
        "Form successfully submitted. Our staff will get back to you as soon as possible."
      )
    })
  }

  render() {
    return (
      <section id="quote" className="pt-0">
        <div className="container text-center">
          <div className="row mt-0">
            <div className="col-md-10 offset-md-1">
              <div className="form-holder">
                <h2>Get Quote Now</h2>
                <p>
                  Leave your details here and our staff will get back to you
                </p>
                <form
                  id="lead-form"
                  name="quote-form"
                  action="/advanx-lab"
                  onSubmit={this.handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        className="form-control"
                        value={this.state.name}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="company"
                        id="company"
                        placeholder="Company Name"
                        className="form-control"
                        value={this.state.company}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="contactNumber"
                        id="contactNumber"
                        placeholder="Contact Number"
                        className="form-control"
                        value={this.state.contactNumber}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <div className="col-md-12 form-group">
                      <textarea
                        name="message"
                        id="message"
                        placeholder="Leave us your message"
                        className="form-control"
                        value={this.state.message}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary submit one-liner mt-3 "
                    disabled={this.state.submitButtonDisabled}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
