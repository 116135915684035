import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"
import LabQuoteForm from "../components/labquoteform"

export default class PartnersPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function () {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "Advanx Lab")
  }

  render() {
    const metadata = {
      title: "Advanx Lab | The First Cloud-Based Laboratory In Malaysia",
      description:
        "Advanx Lab is the first-of-its-kind in Malaysia, offering genetic screening services with a cloud-based platform that is secure and convenient for all your research needs. Nucleic acid extraction, PCR, microarray and DNA sequencing services available. Request for a quote today!",
      image: "https://www.advanxhealth.com/img/advanx-lab/lab-2.jpg",
      url: "https://www.advanxhealth.com/advanx-lab",
    }
    return (
      <Layout>
        <div id="advanx-lab">
          <ProgressBar />
          <HeadMeta metadata={metadata} />
          <section
            className="hero hero-landing bg-gray"
            style={{
              backgroundImage: `url('/img/advanx-lab/lab.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "70vh",
            }}
          >
            <div className="hero-label text-white text-center text-md-left col-12 col-md-6">
              <div>
                <h1 className="mb-0">
                  A D V A N X &sdot;{" "}
                  <span className="font-weight-light">L A B</span>
                </h1>
                <p className="my-0">
                  The first cloud-based laboratory in Malaysia
                </p>
              </div>
            </div>
          </section>
          <section className="py-0">
            <div className="row align-items-center">
              <div className="col-md-6 p-5">
                <p>
                  Advanx Lab is the first-of-its-kind in Malaysia, offering
                  genetic screening services with a cloud-based platform that is
                  secure and convenient for all your research needs.
                </p>
                <p>
                  Equipped with the platform to study gene expression, our main
                  equipment in the lab is the Illumina Infinium HTS Assay that
                  is designed to maximize content flexibility with a
                  High-Throughput Screening capacity for genotyping and
                  copynumber variation (CNV) analysis.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={withPrefix("/img/advanx-lab/lab-2.jpg")}
                  alt="AdvanxLab"
                  className="img-fluid"
                />
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center">
                  <img
                    src={withPrefix("/img/advanx-lab/accurate.svg")}
                    alt="Accurate"
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                  <p className="font-weight-bold mt-3 mb-1">Accuracy</p>
                  <p className="text-primary">Analytical accuracy of 99%</p>
                </div>
                <div className="col-md-4 text-center">
                  <img
                    src={withPrefix("/img/advanx-lab/certified.svg")}
                    alt="Certified"
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                  <p className="font-weight-bold mt-3 mb-1">Certified</p>
                  <p className="text-primary">
                    BSL-2 laboratory & trained lab technicians
                  </p>
                </div>
                <div className="col-md-4 text-center">
                  <img
                    src={withPrefix("/img/advanx-lab/turnaround.svg")}
                    alt="Fast Turnaround"
                    className="img-fluid"
                    style={{ height: "150px" }}
                  />
                  <p className="font-weight-bold mt-3 mb-1">Fast Turnaround</p>
                  <p className="text-primary ">
                    Get results back within 2-3 weeks
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="service" className="pt-0">
            <h1 className="text-center">Services Offered</h1>
            <div className="container">
              <div className="bg-gray row my-5">
                <div
                  className="col-md-4"
                  style={{
                    backgroundImage: `url('/img/advanx-lab/extraction.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "3rem 0rem 0rem 3rem",
                  }}
                ></div>
                <div className="col-md-8 p-4">
                  <div className="d-md-none text-center">
                    <img
                      src={withPrefix("/img/advanx-lab/extraction.jpg")}
                      alt="DNA Extraction"
                      className="img-fluid mb-4 rounded"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <h4 className="text-primary">DNA Extraction</h4>
                  <h5 className="text-primary">What is it?</h5>
                  <p>
                    Standard DNA extraction; processing of either the whole sample in one step or smaller aliquots of the stabilized sample
                  </p>
                  <h5 className="text-primary">Technology</h5>
                  <p>
                    Isohelix GeneFixTM Saliva-Prep 2 DNA Kit
                  </p>
                  <h5 className="text-primary">Specimen needed</h5>
                  <p>
                    4mL Saliva
                  </p>
                  <h5 className="text-primary">Turnaround time</h5>
                  <p>
                    1 day
                  </p>
                  <h5 className="text-primary">Price</h5>
                  <p className="font-weight-bold font-italic">
                    DNA Extraction + DNA Quantification = RM150/sample
                  </p>
                </div>
              </div>
              <div className="bg-gray row my-5">
                <div
                  className="col-md-4 order-2"
                  style={{
                    backgroundImage: `url('/img/advanx-lab/quantification.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "0rem 3rem 3rem 0rem",
                  }}
                ></div>
                <div className="col-md-8 p-4 order-1">
                  <div className="d-md-none text-center">
                    <img
                      src={withPrefix("/img/advanx-lab/quantification.jpg")}
                      alt="DNA Quantification"
                      className="img-fluid mb-4 rounded"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <h4 className="text-primary">DNA Quantification</h4>
                  <h5 className="text-primary">What is it?</h5>
                  <p>
                    Determine the average concentrations and quality of DNA present in a mixture
                  </p>
                  <h5 className="text-primary">Methods</h5>
                  <p>
                    Quality checks using UV Spectrophotometer, fluorometer, and gel electrophoresis
                  </p>
                  <h5 className="text-primary">Specimen needed</h5>
                  <p>
                    Minimum 50 uL DNA sample
                  </p>
                  <h5 className="text-primary">Transportation Requirement</h5>
                  <p>
                    2 - 8&#8451;
                  </p>
                  <h5 className="text-primary">Turnaround time</h5>
                  <p>
                    1 day
                  </p>
                  <h5 className="text-primary">Laboratory Request Form</h5>
                  <a href={withPrefix("/file/customer-sample-submission-form.docx")} className="font-weight-bold font-italic">
                    Sample Submission Form
                  </a>
                </div>
              </div>
              <div className="bg-gray row my-5">
                <div
                  className="col-md-4"
                  style={{
                    backgroundImage: `url('/img/advanx-lab/microarray.jpg')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "3rem 0rem 0rem 3rem",
                  }}
                ></div>
                <div className="col-md-8 p-4">
                  <div className="d-md-none text-center">
                    <img
                      src={withPrefix("/img/advanx-lab/microarray.jpg")}
                      alt="DNA Microarray"
                      className="img-fluid mb-4 rounded"
                      style={{ height: "250px" }}
                    />
                  </div>
                  <h4 className="text-primary">DNA Microarray</h4>
                  <h5 className="text-primary">What is it?</h5>
                  <p>
                    Maximize content flexibility with a High-Throughput Screening capacity for genotyping and copy number variation (CNV) analysis
                  </p>
                  <h5 className="text-primary">Methods</h5>
                  <p>
                    ASA BeadChip Processing
                  </p>
                  <h5 className="text-primary">Technology</h5>
                  <p>
                    Illumina Infinium HTS Assay
                  </p>
                  <h5 className="text-primary">Specimen needed</h5>
                  <p>
                    Saliva or DNA sample
                  </p>
                  <h5 className="text-primary">Turnaround time</h5>
                  <p>
                    1 week
                    <br />
                    <small>
                      *Additional 1 week is required for full BeadChip Processing, DNA extraction and sample analysis
                    </small>
                  </p>
                  <h5 className="text-primary">Price</h5>
                  <p className="font-weight-bold font-italic">
                    ASA BeadChip, Processing, & scanning = RM550/sample
                    <br />
                    Full ASA Beadchip Processing = RM700/sample
                    <br />
                    (*including DNA extraction)
                  </p>
                </div>
              </div>

            </div>
          </section>
          <LabQuoteForm />
        </div>
      </Layout>
    )
  }
}
